.wrapper {
  position: relative;
  padding: 0;
}

.icons {
  text-align: center;
  margin-top: 4rem;
}

.icons a {
  color: white !important;
  font-size: 2rem;
  display: inline-block;
  margin: 0 6px;
}

.infoWrapper {
  /* background: #daa539; */
  /* background: #c97f04; */
  /* background: #89212A; */
  background: var(--brown);
  width: 100%;
  padding-top: 32px;
  border-bottom: 4px solid var(--grey);
  overflow: hidden;
}

.infoHeaderWrapper {
  width: 100%;
  position: relative;
  margin-bottom: 58px;
  user-select: none;
}

.openLeftCorner {
  top: -18px;
  left: -18px;
  position: absolute;
  transform: rotate(90deg);
  width: 80px;
  height: auto;
}

.openRightCorner {
  top: -18px;
  right: -18px;
  position: absolute;
  transform: rotate(-90deg) scaleX(-1);
  width: 80px;
  height: auto;
}

.infoOpenDays {
  font-family: var(--header-font-stack);
  color: var(--white);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.infoCost {
  font-family: var(--header-font-stack);
  color: var(--cream);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 488px) {
  .openLeftCorner {
    width: 120px;
  }
  .openRightCorner {
    width: 120px;
  }
  .infoOpenDays {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .openLeftCorner {
    width: 180px;
  }
  .openRightCorner {
    width: 180px;
  }
  .infoOpenDays {
    font-size: 42px;
  }
}

.mapAddressHoursWrapper {
  width: 100%;
}

.mapAddressHoursWrapperInner {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.map {
  width: 100%;
  min-height: 420px;
  border-top: 4px solid var(--grey);
}

.addressHoursWrapper {
  background-color: #c0d4db;
  background-image: url('./img/visitBackground.jpg');
  background-size: cover;
  background-position: center center;
  width: 100%;
  border-top: 4px solid var(--cream);
}

.addressHoursWrapperInner {
  display: block;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px 0;
}

.addressWrapper,
.hoursWrapper {
  width: 90%;
  max-width: 380px;
  padding: 18px;
  margin: 0 auto;
  /* background: var(--light-blue); */
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.8); */
}

.addressWrapper {
  margin-bottom: 24px;
}

.addressHeaderWrapper,
.hoursHeaderWrapper {
  border-top: 3px solid var(--white);
  border-bottom: 3px solid var(--white);
  padding: 4px 0;
}

.addressHeader,
.hoursHeader {
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  font-family: var(--header-font-stack);
  font-weight: 600;
  font-size: 28px;
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
  user-select: none;
}

.addressContentWrapper,
.hoursContentWrapper {
  margin-top: 24px;
  font-style: normal;
}

.addressContentWrapper > span,
.hoursContentWrapper > span {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--body-font-stack);
  color: var(--white);
}

.bottomWrapper {
  width: 100%;
  /* background-image: url('./img/danInFront.jpg'); */
  /* background-size: cover; */
  /* background-position: center center; */
  /* background-attachment: fixed; */
}

.bottomWrapper:before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url('./img/danInFront.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.spacer {
  height: 75vh;
}

.bottomWrapperInner {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 4px solid var(--grey);
}

.aboutWrapper {
  padding: 96px 0;
}

@media (min-width: 768px) {
  .addressHoursWrapperInner {
    display: flex;
  }
  .addressWrapper,
  .hoursWrapper {
    width: 42%;
    max-width: 380px;
    height: 224px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .mapAddressHoursWrapper {
    border-top: 4px solid var(--cream);
    background-color: #c0d4db;
    background-image: url('./img/visitBackground.jpg');
    background-size: cover;
    background-position: center center;
  }
  .mapAddressHoursWrapperInner {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .map {
    border-top: none;
    width: 50%;
    padding: 58px;
  }
  .addressHoursWrapper {
    width: 50%;
    height: calc(100vh - 379px);
    min-height: 420px;
    border: none;
    background: transparent;
  }
  .addressHoursWrapperInner {
    background: transparent;
    padding: 0;
  }
  .addressWrapper,
  .hoursWrapper {
    height: 256px;
  }
}
