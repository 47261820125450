.wrapper {
  position: relative;
  padding: 40px 0 80px;
}

.innerWrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.image {
  position: relative;
  width: 80%;
  padding-top: 80%;
  margin: 0 auto 48px;
}

.slideshowWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.info {
  width: 100%;
}

.title {
  font-family: var(--body-font-stack);
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.price {
  font-family: var(--body-font-stack);
  color: var(--dark-grey);
  font-size: 18px;
  font-weight: 700;
  margin-top: 0;
}

.addToCartButton {
  margin-bottom: 6px;
}

.samplerWrapper {
  margin: 16px 0;
  width: 100%;
}

.samplerLabel {
  font-family: var(--body-font-stack);
  color: var(--dark-grey);
  font-size: 16px;
  user-select: none;
  cursor: default;
}

.sampler {
  width: 100%;
}

.description {
  font-family: var(--body-font-stack);
  color: var(--black);
  line-height: 26px;
  font-size: 15px;
  padding: 8px 0;
}

.backLinkWrapper {
  margin: 0 auto 32px;
}

.backLink {
  font-size: 16px;
}

@media (min-width: 640px) {
  .image {
    width: 40%;
    padding-top: 40%;
  }
  .info {
    width: 50%;
  }
  .backLinkWrapper {
    width: 90%;
  }
}
