.wrapper {
}

.header {
  font-family: var(--header-font-stack);
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: var(--black);
  cursor: default;
  user-select: none;
}

@media (min-width: 768px) {
  .header {
    text-align: left;
  }
}

.message {
  font-family: var(--body-font-stack);
  font-size: 14px;
}

label.label {
  font-size: 14px !important;
  user-select: none;
}

.validationLabel {
  font-size: 12px !important;
  user-select: none;
}

.input,
.validationLabel,
label.label {
  font-family: var(--body-font-stack) !important;
  color: var(--black) !important;
}

.charactersRemaining {
  margin-top: 6px;
  display: block;
  font-family: var(--body-font-stack);
  color: var(--grey);
  font-size: 12px;
}
