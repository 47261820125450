.wrapper {
  display: inline-block;
  height: 100%;
  padding: 0 16px;
}

.textWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text {
  font-family: var(--body-font-stack);
  font-weight: 600;
  font-size: 16px;
  color: var(--light-brown);
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none;
  position: relative;
}

.text.inverted {
  color: var(--light-cream);
}

.wrapper:focus > .textWrapper > .text:before,
.wrapper:hover > .textWrapper > .text:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.text.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.text:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: var(--light-brown);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.text.inverted:before {
  background-color: var(--light-cream);
}

@media (min-width: 992px) {
  .wrapper {
    padding: 0 24px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    padding: 0 32px;
  }
}
