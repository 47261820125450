.wrapper {
  display: block;
  text-align: center;
  padding: 14px 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.text {
  font-family: var(--body-font-stack);
  font-size: 16px;
  font-weight: 600;
  color: var(--light-brown);
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.wrapper:hover,
.wrapper:focus,
.wrapper.active {
  background: var(--cream);
}

.wrapper:hover > .text,
.wrapper:focus > .text,
.wrapper.active > .text {
  color: var(--dark-brown);
}

.wrapper.inverted:hover,
.wrapper.inverted:focus {
  background: var(--brown);
}

.wrapper.inverted:hover > .text,
.wrapper.inverted:focus > .text {
  color: var(--white);
}

.wrapper.inverted > .text {
  color: var(--light-cream);
}
