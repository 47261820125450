.wrapper {
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  font-style: normal;
}

.text {
  display: block;
  font-family: var(--body-font-stack);
  font-weight: 400;
  color: var(--light-brown);
  font-size: 14px;
  line-height: 26px;
}

.wrapper.inverted > .text {
  color: var(--light-cream);
}
