.wrapper:hover {
  cursor: pointer;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  width: 48px;
  background: transparent;
  margin: auto 0;
  padding: 10px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  /* outline: none; */
  border: none;
  border-radius: 50%;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.wrapper.expanded {
  background: var(--cream);
}

.wrapper.inverted.expanded {
  background: var(--brown);
}

.bar {
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  background: var(--dark-brown);
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.wrapper.inverted > .bar {
  background: var(--light-cream);
}

.wrapper.inverted.expanded > .bar {
  background: var(--white);
}

@media (min-width: 768px) {
  .wrapper {
    display: none;
  }
}
