.headerWrapper {
  background: var(--light-cream);
}

.headerWrapper.inverted {
  background: var(--dark-brown);
}

.wrapper {
  width: 100%;
  -webkit-transition: 0.6s background ease;
  transition: 0.6s background ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--light-cream);
  z-index: 999;
  border-bottom: 4px solid var(--cream);
}

.wrapper.inverted {
  background: var(--dark-brown);
  border-bottom: 4px solid var(--cream);
}

.wrapper.scrolled {
  background: var(--light-cream);
  position: fixed;
  opacity: 1;
  border-bottom: 1px solid #bbb;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  -webkit-animation: fadein 0.33s linear;
  animation: fadein 0.33s linear;
}

.wrapper.scrolled.inverted {
  background: var(--dark-brown);
  border-bottom: 4px solid var(--cream);
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pseudoWrapper {
  position: relative;
  height: 147px;
}
.pseudoWrapper.expanded {
  height: 335px;
}

@media (min-width: 332px) {
  .pseudoWrapper {
    height: 107px;
  }
  .pseudoWrapper.expanded {
    height: 295px;
  }
}

@media (min-width: 992px) {
  .pseudoWrapper {
    height: 151px;
  }
  .pseudoWrapper.expanded {
    height: 151px;
  }
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
