.wrapper {
  position: relative;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100vh - 151px - 126px);
  min-height: 600px;
}

@media (min-width: 332px) {
  .wrapper {
    height: calc(100vh - 111px - 126px);
  }
}

@media (min-width: 992px) {
  .wrapper {
    height: calc(100vh - 155px - 126px);
  }
}
