.wrapper,
.wrapper > div,
.wrapper > div > div,
.wrapper > div > div > div,
.wrapper > div > div > div > div,
.eachFade,
.imageContainer {
  position: relative !important;
  left: 0;
  top: 0 !important;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  pointer-events: none;
}

.imageContainer {
  background-size: cover;
  background-position: center 40%;
  background-repeat: no-repeat;
  -webkit-animation: fadein 1.5s ease;
  animation: fadein 1.5s ease;
}

.pauseButton {
  position: absolute;
  bottom: 0px;
  z-index: 99999;
}
