.wrapper {
  display: block;
  padding: 18px 0 24px;
}

.wrapper.scrolled {
  padding: 12px 0 12px;
  /* transition: 0.3s all ease; */
}

.logo {
  font-family: var(--header-font-stack);
  line-height: 36px;
  color: var(--dark-brown);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 36px;
  margin: 0 0 14px 0;
}

.wrapper.inverted > .logo {
  color: var(--white);
}

.wrapper.scrolled > .logo {
  font-size: 32px;
}

.slogan {
  font-family: var(--body-font-stack);
  color: var(--light-brown);
  font-size: 16px;
  font-weight: 400;
}

.wrapper.inverted > .slogan {
  color: var(--light-cream);
}

.wrapper.scrolled > .slogan {
  display: none;
}

@media (min-width: 332px) {
  .logo {
    line-height: 46px;
    margin: 0;
  }
  .slogan {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    padding: 32px 0 38px;
  }
  .logo {
    line-height: 62px;
    font-size: 48px;
  }
  .wrapper.scrolled > .logo {
    font-size: 36px;
  }
  .slogan {
    font-size: 16px;
  }
}
