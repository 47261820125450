@import url("https://fonts.googleapis.com/css?family=Cormorant:700|Muli:400,600");

/*****************************
******************* variables
*****************************/

:root {
  /* --dark-brown: #521d1d; */
  --dark-brown: #511921;
  /* --brown: #775350; */
  /* --brown: #75363f; */
  --brown: #a03f4b;
  /* --light-brown: #937e7c; */
  --light-brown: #9f6f76;
  --dark-black: #000000;
  --black: #373737;
  --dark-grey: #8b8b8b;
  --grey: #bbbbbb;
  --light-grey: #efefef;
  --white: #ffffff;
  /* --dark-cream: #ead6a1; */
  --dark-cream: #e3bd66;
  /* --cream: #f4e1a1; */
  --cream: #ebd091;
  /* --light-cream: #fcf7e6; */
  --light-cream: #f7ecd2;
  --blue: #617f7f;
  --light-blue: #7a9999;
  --header-font-stack: Cormorant, serif;
  --body-font-stack: Muli, sans-serif;
}

/*****************************
********************* general
*****************************/

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

body {
  height: 100vh;
  overflow: auto;
  /* background-color: var(--dark-brown); */
}

html {
  height: 100%;
  margin: 0;
  overflow: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/*****************************
******************* technical
*****************************/

.addToCartButton,
.addToCartButton:disabled {
  background: var(--brown);
  color: var(--white);
  padding: 8px 16px;
  font-family: var(--body-font-stack);
  font-weight: 600;
  border: none;
  border-radius: 16px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  display: block;
  margin: 0 auto !important;
}

@media (min-width: 768px) {
  .addToCartButton,
  .addToCartButton:disabled {
    margin: 0 !important;
  }
}

.addToCartButton:hover,
.addToCartButton:focus {
  background: var(--dark-brown);
  cursor: pointer;
}

.addToCartButton:disabled,
.addToCartButton:disabled:hover {
  cursor: default;
  background: var(--light-brown);
  pointer-events: none;
}

.indicators {
  height: 12px !important;
}

.react-slideshow-container > .nav {
  height: 100% !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent !important;
  font-size: 32px !important;
  color: var(--grey) !important;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.react-slideshow-container > .nav:hover {
  color: var(--white) !important;
  font-weight: bold !important;
}

.react-h5-audio-player {
  margin: 6px 0 !important;
}

.flex {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  outline: none !important;
}

.toggle-play-wrapper {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 0 14px !important;
  flex: 1 0 14px !important;
  margin-right: 12px !important;
}

.toggle-play-button {
  background: var(--brown) !important;
  border-radius: none !important;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.toggle-play-button:hover {
  cursor: pointer;
  background: var(--dark-brown) !important;
}

.pause-icon {
  -webkit-box-shadow: var(--brown) 7px 0px 0px 0px inset !important;
  box-shadow: var(--brown) 7px 0px 0px 0px inset !important;
  -webkit-transition: 0.3s -webkit-box-shadow ease;
  transition: 0.3s -webkit-box-shadow ease;
  transition: 0.3s box-shadow ease;
  transition: 0.3s box-shadow ease, 0.3s -webkit-box-shadow ease;
}

.toggle-play-button:hover > .pause-icon {
  -webkit-box-shadow: var(--dark-brown) 7px 0px 0px 0px inset !important;
  box-shadow: var(--dark-brown) 7px 0px 0px 0px inset !important;
}

.indicator {
  background: var(--brown) !important;
  opacity: 1 !important;
}

.indicator:hover {
  cursor: default !important;
}

.volumn {
  border-bottom-color: var(--brown) !important;
}

.time {
  color: transparent !important;
  font-family: var(--body-font-stack) !important;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.current-time {
  color: var(--black) !important;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
