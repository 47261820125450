.wrapper {
  text-align: right;
  width: 100%;
  height: 100%;
}

.mapWrapper {
  overflow: hidden;
  background-color: var(--light-grey);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 64px;
}

.map {
  width: 100%;
  height: 100%;
}
