@import url(https://fonts.googleapis.com/css?family=Cormorant:700|Muli:400,600);
/*****************************
******************* variables
*****************************/

:root {
  /* --dark-brown: #521d1d; */
  --dark-brown: #511921;
  /* --brown: #775350; */
  /* --brown: #75363f; */
  --brown: #a03f4b;
  /* --light-brown: #937e7c; */
  --light-brown: #9f6f76;
  --dark-black: #000000;
  --black: #373737;
  --dark-grey: #8b8b8b;
  --grey: #bbbbbb;
  --light-grey: #efefef;
  --white: #ffffff;
  /* --dark-cream: #ead6a1; */
  --dark-cream: #e3bd66;
  /* --cream: #f4e1a1; */
  --cream: #ebd091;
  /* --light-cream: #fcf7e6; */
  --light-cream: #f7ecd2;
  --blue: #617f7f;
  --light-blue: #7a9999;
  --header-font-stack: Cormorant, serif;
  --body-font-stack: Muli, sans-serif;
}

/*****************************
********************* general
*****************************/

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

body {
  height: 100vh;
  overflow: auto;
  /* background-color: var(--dark-brown); */
}

html {
  height: 100%;
  margin: 0;
  overflow: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/*****************************
******************* technical
*****************************/

.addToCartButton,
.addToCartButton:disabled {
  background: var(--brown);
  color: var(--white);
  padding: 8px 16px;
  font-family: var(--body-font-stack);
  font-weight: 600;
  border: none;
  border-radius: 16px;
  transition: 0.3s all ease;
  display: block;
  margin: 0 auto !important;
}

@media (min-width: 768px) {
  .addToCartButton,
  .addToCartButton:disabled {
    margin: 0 !important;
  }
}

.addToCartButton:hover,
.addToCartButton:focus {
  background: var(--dark-brown);
  cursor: pointer;
}

.addToCartButton:disabled,
.addToCartButton:disabled:hover {
  cursor: default;
  background: var(--light-brown);
  pointer-events: none;
}

.indicators {
  height: 12px !important;
}

.react-slideshow-container > .nav {
  height: 100% !important;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  font-size: 32px !important;
  color: var(--grey) !important;
  transition: 0.3s all ease;
  -webkit-user-select: none;
  user-select: none;
}
.react-slideshow-container > .nav:hover {
  color: var(--white) !important;
  font-weight: bold !important;
}

.react-h5-audio-player {
  margin: 6px 0 !important;
}

.flex {
  box-shadow: none !important;
  text-shadow: none !important;
  outline: none !important;
}

.toggle-play-wrapper {
  flex: 1 0 14px !important;
  margin-right: 12px !important;
}

.toggle-play-button {
  background: var(--brown) !important;
  border-radius: none !important;
  transition: 0.3s all ease;
}

.toggle-play-button:hover {
  cursor: pointer;
  background: var(--dark-brown) !important;
}

.pause-icon {
  box-shadow: var(--brown) 7px 0px 0px 0px inset !important;
  transition: 0.3s box-shadow ease;
}

.toggle-play-button:hover > .pause-icon {
  box-shadow: var(--dark-brown) 7px 0px 0px 0px inset !important;
}

.indicator {
  background: var(--brown) !important;
  opacity: 1 !important;
}

.indicator:hover {
  cursor: default !important;
}

.volumn {
  border-bottom-color: var(--brown) !important;
}

.time {
  color: transparent !important;
  font-family: var(--body-font-stack) !important;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.current-time {
  color: var(--black) !important;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.Logo_wrapper__3qbEA {
  display: block;
  padding: 18px 0 24px;
}

.Logo_wrapper__3qbEA.Logo_scrolled__23Vp0 {
  padding: 12px 0 12px;
  /* transition: 0.3s all ease; */
}

.Logo_logo__3noLJ {
  font-family: var(--header-font-stack);
  line-height: 36px;
  color: var(--dark-brown);
  -webkit-user-select: none;
  user-select: none;
  font-size: 36px;
  margin: 0 0 14px 0;
}

.Logo_wrapper__3qbEA.Logo_inverted__3-ns2 > .Logo_logo__3noLJ {
  color: var(--white);
}

.Logo_wrapper__3qbEA.Logo_scrolled__23Vp0 > .Logo_logo__3noLJ {
  font-size: 32px;
}

.Logo_slogan__m5LTS {
  font-family: var(--body-font-stack);
  color: var(--light-brown);
  font-size: 16px;
  font-weight: 400;
}

.Logo_wrapper__3qbEA.Logo_inverted__3-ns2 > .Logo_slogan__m5LTS {
  color: var(--light-cream);
}

.Logo_wrapper__3qbEA.Logo_scrolled__23Vp0 > .Logo_slogan__m5LTS {
  display: none;
}

@media (min-width: 332px) {
  .Logo_logo__3noLJ {
    line-height: 46px;
    margin: 0;
  }
  .Logo_slogan__m5LTS {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .Logo_wrapper__3qbEA {
    padding: 32px 0 38px;
  }
  .Logo_logo__3noLJ {
    line-height: 62px;
    font-size: 48px;
  }
  .Logo_wrapper__3qbEA.Logo_scrolled__23Vp0 > .Logo_logo__3noLJ {
    font-size: 36px;
  }
  .Logo_slogan__m5LTS {
    font-size: 16px;
  }
}

.ToggleButton_wrapper__3vs0h:hover {
  cursor: pointer;
}

.ToggleButton_wrapper__3vs0h {
  display: flex;
  height: 48px;
  width: 48px;
  background: transparent;
  margin: auto 0;
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* outline: none; */
  border: none;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.ToggleButton_wrapper__3vs0h.ToggleButton_expanded__2B6Eq {
  background: var(--cream);
}

.ToggleButton_wrapper__3vs0h.ToggleButton_inverted__13X6i.ToggleButton_expanded__2B6Eq {
  background: var(--brown);
}

.ToggleButton_bar__XZHHh {
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  background: var(--dark-brown);
  transition: 0.3s all ease;
}

.ToggleButton_wrapper__3vs0h.ToggleButton_inverted__13X6i > .ToggleButton_bar__XZHHh {
  background: var(--light-cream);
}

.ToggleButton_wrapper__3vs0h.ToggleButton_inverted__13X6i.ToggleButton_expanded__2B6Eq > .ToggleButton_bar__XZHHh {
  background: var(--white);
}

@media (min-width: 768px) {
  .ToggleButton_wrapper__3vs0h {
    display: none;
  }
}

.MenuItem_wrapper__aT7u6 {
  display: inline-block;
  height: 100%;
  padding: 0 16px;
}

.MenuItem_textWrapper__QnC0y {
  display: flex;
  height: 100%;
  align-items: center;
}

.MenuItem_text__34IvW {
  font-family: var(--body-font-stack);
  font-weight: 600;
  font-size: 16px;
  color: var(--light-brown);
  transition: 0.3s all ease;
  text-decoration: none;
  position: relative;
}

.MenuItem_text__34IvW.MenuItem_inverted__1bvhx {
  color: var(--light-cream);
}

.MenuItem_wrapper__aT7u6:focus > .MenuItem_textWrapper__QnC0y > .MenuItem_text__34IvW:before,
.MenuItem_wrapper__aT7u6:hover > .MenuItem_textWrapper__QnC0y > .MenuItem_text__34IvW:before {
  visibility: visible;
  transform: scaleX(1);
}

.MenuItem_text__34IvW.MenuItem_active__2WkNU:before {
  visibility: visible;
  transform: scaleX(1);
}

.MenuItem_text__34IvW:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: var(--light-brown);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.MenuItem_text__34IvW.MenuItem_inverted__1bvhx:before {
  background-color: var(--light-cream);
}

@media (min-width: 992px) {
  .MenuItem_wrapper__aT7u6 {
    padding: 0 24px;
  }
}

@media (min-width: 1200px) {
  .MenuItem_wrapper__aT7u6 {
    padding: 0 32px;
  }
}

.MenuItems_wrapper__3Onpm {
  height: 100%;
}

.Menu_wrapper__1B9fp {
  display: none;
}

@media (min-width: 768px) {
  .Menu_wrapper__1B9fp {
    display: block;
  }
}

.MenuItem_wrapper__wQAUB {
  display: block;
  text-align: center;
  padding: 14px 0;
  transition: 0.5s all ease;
}

.MenuItem_text__1em2Q {
  font-family: var(--body-font-stack);
  font-size: 16px;
  font-weight: 600;
  color: var(--light-brown);
  transition: 0.5s all ease;
}

.MenuItem_wrapper__wQAUB:hover,
.MenuItem_wrapper__wQAUB:focus,
.MenuItem_wrapper__wQAUB.MenuItem_active__XP8hQ {
  background: var(--cream);
}

.MenuItem_wrapper__wQAUB:hover > .MenuItem_text__1em2Q,
.MenuItem_wrapper__wQAUB:focus > .MenuItem_text__1em2Q,
.MenuItem_wrapper__wQAUB.MenuItem_active__XP8hQ > .MenuItem_text__1em2Q {
  color: var(--dark-brown);
}

.MenuItem_wrapper__wQAUB.MenuItem_inverted__3G4PN:hover,
.MenuItem_wrapper__wQAUB.MenuItem_inverted__3G4PN:focus {
  background: var(--brown);
}

.MenuItem_wrapper__wQAUB.MenuItem_inverted__3G4PN:hover > .MenuItem_text__1em2Q,
.MenuItem_wrapper__wQAUB.MenuItem_inverted__3G4PN:focus > .MenuItem_text__1em2Q {
  color: var(--white);
}

.MenuItem_wrapper__wQAUB.MenuItem_inverted__3G4PN > .MenuItem_text__1em2Q {
  color: var(--light-cream);
}

.CollapsibleMenu_wrapper__hEwQn {
  display: block;
}

@media (min-width: 768px) {
  .CollapsibleMenu_wrapper__hEwQn {
    display: none;
  }
}

.Navigation_headerWrapper__1AHl8 {
  background: var(--light-cream);
}

.Navigation_headerWrapper__1AHl8.Navigation_inverted__3vtDT {
  background: var(--dark-brown);
}

.Navigation_wrapper__3yIs4 {
  width: 100%;
  transition: 0.6s background ease;
  -webkit-user-select: none;
  user-select: none;
  background: var(--light-cream);
  z-index: 999;
  border-bottom: 4px solid var(--cream);
}

.Navigation_wrapper__3yIs4.Navigation_inverted__3vtDT {
  background: var(--dark-brown);
  border-bottom: 4px solid var(--cream);
}

.Navigation_wrapper__3yIs4.Navigation_scrolled__ZB2M4 {
  background: var(--light-cream);
  position: fixed;
  opacity: 1;
  border-bottom: 1px solid #bbb;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  animation: Navigation_fadein__IRSOp 0.33s linear;
}

.Navigation_wrapper__3yIs4.Navigation_scrolled__ZB2M4.Navigation_inverted__3vtDT {
  background: var(--dark-brown);
  border-bottom: 4px solid var(--cream);
}

@keyframes Navigation_fadein__IRSOp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Navigation_pseudoWrapper__1NTCF {
  position: relative;
  height: 147px;
}
.Navigation_pseudoWrapper__1NTCF.Navigation_expanded__15p5E {
  height: 335px;
}

@media (min-width: 332px) {
  .Navigation_pseudoWrapper__1NTCF {
    height: 107px;
  }
  .Navigation_pseudoWrapper__1NTCF.Navigation_expanded__15p5E {
    height: 295px;
  }
}

@media (min-width: 992px) {
  .Navigation_pseudoWrapper__1NTCF {
    height: 151px;
  }
  .Navigation_pseudoWrapper__1NTCF.Navigation_expanded__15p5E {
    height: 151px;
  }
}

.Navigation_container__vouaL {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.Hours_wrapper__3tCpZ {
  transition: 0.3s all ease;
}

.Hours_text__eEPIA {
  display: block;
  font-family: var(--body-font-stack);
  font-weight: 400;
  color: var(--light-brown);
  font-size: 14px;
  line-height: 26px;
}

.Hours_wrapper__3tCpZ.Hours_inverted__3Q4Qq > .Hours_text__eEPIA {
  color: var(--light-cream);
}

.Hours_contactLink__3weGE {
  font-family: var(--body-font-stack);
  font-weight: 400;
  font-size: 14px;
  color: var(--light-brown);
  transition: 0.3s all ease;
  text-decoration: none;
  position: relative;
}

.Hours_wrapper__3tCpZ.Hours_inverted__3Q4Qq > .Hours_contactLinkWrapper__c7jal > .Hours_contactLinkWrapperInner__21t5w > .Hours_contactLink__3weGE {
  color: var(--light-cream);
}

.Hours_contactLinkWrapper__c7jal:hover > .Hours_contactLinkWrapperInner__21t5w > .Hours_contactLink__3weGE:before {
  visibility: visible;
  transform: scaleX(1);
}

.Hours_contactLink__3weGE:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: var(--light-brown);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.Hours_wrapper__3tCpZ.Hours_inverted__3Q4Qq > .Hours_contactLinkWrapper__c7jal > .Hours_contactLinkWrapperInner__21t5w > .Hours_contactLink__3weGE:before {
  background-color: var(--light-cream);
}

.Address_wrapper__3KtGB {
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  font-style: normal;
}

.Address_text__nUViA {
  display: block;
  font-family: var(--body-font-stack);
  font-weight: 400;
  color: var(--light-brown);
  font-size: 14px;
  line-height: 26px;
}

.Address_wrapper__3KtGB.Address_inverted__1Jl4l > .Address_text__nUViA {
  color: var(--light-cream);
}

.Footer_wrapper__1YH_P {
  flex-shrink: 0;
  padding: 22px 0;
  background: var(--light-cream);
  transition: 0.6s background ease;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border-top: 4px solid var(--cream);
}

.Footer_wrapper__1YH_P.Footer_inverted__11dmV {
  background: var(--dark-brown);
  border-top: 4px solid var(--cream);
}

.Footer_content__GADOx {
  display: flex;
  justify-content: flex-end;
}

.Footer_innerContent__1fUH- {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Slideshow_wrapper__2CEHI,
.Slideshow_wrapper__2CEHI > div,
.Slideshow_wrapper__2CEHI > div > div,
.Slideshow_wrapper__2CEHI > div > div > div,
.Slideshow_wrapper__2CEHI > div > div > div > div,
.Slideshow_eachFade__2ERC_,
.Slideshow_imageContainer__1LAdZ {
  position: relative !important;
  left: 0;
  top: 0 !important;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  pointer-events: none;
}

.Slideshow_imageContainer__1LAdZ {
  background-size: cover;
  background-position: center 40%;
  background-repeat: no-repeat;
  animation: Slideshow_fadein__1Eedf 1.5s ease;
}

.Slideshow_pauseButton__3hpdA {
  position: absolute;
  bottom: 0px;
  z-index: 99999;
}

.Landing_wrapper__2CgDq {
  position: relative;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100vh - 151px - 126px);
  min-height: 600px;
}

@media (min-width: 332px) {
  .Landing_wrapper__2CgDq {
    height: calc(100vh - 111px - 126px);
  }
}

@media (min-width: 992px) {
  .Landing_wrapper__2CgDq {
    height: calc(100vh - 155px - 126px);
  }
}

.Map_wrapper__2P40H {
  text-align: right;
  width: 100%;
  height: 100%;
}

.Map_mapWrapper__1A5IB {
  overflow: hidden;
  background-color: var(--light-grey);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 64px;
}

.Map_map__c0SrZ {
  width: 100%;
  height: 100%;
}

.About_wrapper__1NpN7 {
}

.About_welcomeLine__416kI {
  font-weight: 600;
  font-family: var(--header-font-stack);
  font-size: 26px;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

.About_text__2WxZq {
  font-family: var(--body-font-stack);
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  color: var(--black);
  padding: 8px 0;
}

.About_icons__2AWDy {
  padding-top: 56px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.About_icons__2AWDy > a {
  display: inline-block;
}

.About_icons__2AWDy > a > i {
  color: var(--black);
  font-size: 32px;
  margin: 0 12px;
  transition: 0.3s all ease;
}

.About_icons__2AWDy > a:hover > i {
  transform: scale(1.1);
}

.Visit_wrapper__zWDc9 {
  position: relative;
  padding: 0;
}

.Visit_icons__1lcQ0 {
  text-align: center;
  margin-top: 4rem;
}

.Visit_icons__1lcQ0 a {
  color: white !important;
  font-size: 2rem;
  display: inline-block;
  margin: 0 6px;
}

.Visit_infoWrapper__ojFMj {
  /* background: #daa539; */
  /* background: #c97f04; */
  /* background: #89212A; */
  background: var(--brown);
  width: 100%;
  padding-top: 32px;
  border-bottom: 4px solid var(--grey);
  overflow: hidden;
}

.Visit_infoHeaderWrapper__3ohN7 {
  width: 100%;
  position: relative;
  margin-bottom: 58px;
  -webkit-user-select: none;
          user-select: none;
}

.Visit_openLeftCorner__26qlF {
  top: -18px;
  left: -18px;
  position: absolute;
  transform: rotate(90deg);
  width: 80px;
  height: auto;
}

.Visit_openRightCorner__2CC5V {
  top: -18px;
  right: -18px;
  position: absolute;
  transform: rotate(-90deg) scaleX(-1);
  width: 80px;
  height: auto;
}

.Visit_infoOpenDays__1KQMh {
  font-family: var(--header-font-stack);
  color: var(--white);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.Visit_infoCost__uaD8d {
  font-family: var(--header-font-stack);
  color: var(--cream);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 488px) {
  .Visit_openLeftCorner__26qlF {
    width: 120px;
  }
  .Visit_openRightCorner__2CC5V {
    width: 120px;
  }
  .Visit_infoOpenDays__1KQMh {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .Visit_openLeftCorner__26qlF {
    width: 180px;
  }
  .Visit_openRightCorner__2CC5V {
    width: 180px;
  }
  .Visit_infoOpenDays__1KQMh {
    font-size: 42px;
  }
}

.Visit_mapAddressHoursWrapper__2SoJ- {
  width: 100%;
}

.Visit_mapAddressHoursWrapperInner__1Q6Gu {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
}

.Visit_map__1MtOA {
  width: 100%;
  min-height: 420px;
  border-top: 4px solid var(--grey);
}

.Visit_addressHoursWrapper__3uGZC {
  background-color: #c0d4db;
  background-image: url(/static/media/visitBackground.e2ef5f10.jpg);
  background-size: cover;
  background-position: center center;
  width: 100%;
  border-top: 4px solid var(--cream);
}

.Visit_addressHoursWrapperInner__1mG_D {
  display: block;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px 0;
}

.Visit_addressWrapper__19sR8,
.Visit_hoursWrapper__wTQ0z {
  width: 90%;
  max-width: 380px;
  padding: 18px;
  margin: 0 auto;
  /* background: var(--light-blue); */
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.8); */
}

.Visit_addressWrapper__19sR8 {
  margin-bottom: 24px;
}

.Visit_addressHeaderWrapper__2BdxU,
.Visit_hoursHeaderWrapper__1CMhR {
  border-top: 3px solid var(--white);
  border-bottom: 3px solid var(--white);
  padding: 4px 0;
}

.Visit_addressHeader__2uSZX,
.Visit_hoursHeader__1N6bQ {
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  font-family: var(--header-font-stack);
  font-weight: 600;
  font-size: 28px;
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
  -webkit-user-select: none;
          user-select: none;
}

.Visit_addressContentWrapper__DRGnT,
.Visit_hoursContentWrapper__3CnYa {
  margin-top: 24px;
  font-style: normal;
}

.Visit_addressContentWrapper__DRGnT > span,
.Visit_hoursContentWrapper__3CnYa > span {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--body-font-stack);
  color: var(--white);
}

.Visit_bottomWrapper__pWho5 {
  width: 100%;
  /* background-image: url('./img/danInFront.jpg'); */
  /* background-size: cover; */
  /* background-position: center center; */
  /* background-attachment: fixed; */
}

.Visit_bottomWrapper__pWho5:before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(/static/media/danInFront.e3c12757.jpg) no-repeat center center;
  background-size: cover;
}

.Visit_spacer__2wTnK {
  height: 75vh;
}

.Visit_bottomWrapperInner__1LnOf {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 4px solid var(--grey);
}

.Visit_aboutWrapper__3pBJy {
  padding: 96px 0;
}

@media (min-width: 768px) {
  .Visit_addressHoursWrapperInner__1mG_D {
    display: flex;
  }
  .Visit_addressWrapper__19sR8,
  .Visit_hoursWrapper__wTQ0z {
    width: 42%;
    max-width: 380px;
    height: 224px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .Visit_mapAddressHoursWrapper__2SoJ- {
    border-top: 4px solid var(--cream);
    background-color: #c0d4db;
    background-image: url(/static/media/visitBackground.e2ef5f10.jpg);
    background-size: cover;
    background-position: center center;
  }
  .Visit_mapAddressHoursWrapperInner__1Q6Gu {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .Visit_map__1MtOA {
    border-top: none;
    width: 50%;
    padding: 58px;
  }
  .Visit_addressHoursWrapper__3uGZC {
    width: 50%;
    height: calc(100vh - 379px);
    min-height: 420px;
    border: none;
    background: transparent;
  }
  .Visit_addressHoursWrapperInner__1mG_D {
    background: transparent;
    padding: 0;
  }
  .Visit_addressWrapper__19sR8,
  .Visit_hoursWrapper__wTQ0z {
    height: 256px;
  }
}

.Story_wrapper__2Dpdt {
  position: relative;
  padding: 40px 0 80px;
}

.Story_header__qimUi {
  font-family: var(--header-font-stack);
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  margin: 0 0 12px 0;
  color: var(--black);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.Story_subHeader__1bO96 {
  font-family: var(--body-font-stack);
  text-align: center;
  font-weight: 600;
  margin: 0 0 32px 0;
  font-size: 15px;
  color: var(--dark-grey);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.Story_text__iXHbY {
  font-family: var(--body-font-stack);
  font-weight: 400;
  line-height: 26px;
  font-size: 15px;
  color: var(--black);
  padding: 8px 0;
}

.Story_museeMecaniqueLogo__zenvm {
  position: relative;
  margin-top: 32px;
}

.Story_steamCycle__25JlA,
.Story_museeMecaniqueLogo__zenvm {
  margin: 0 auto 22px;
  display: block;
  width: 90%;
  border: 2px solid var(--black);
}

@media (min-width: 768px) {
  .Story_header__qimUi,
  .Story_subHeader__1bO96 {
    text-align: left;
  }
  .Story_steamCycle__25JlA,
  .Story_museeMecaniqueLogo__zenvm {
    display: inline-block;
    width: 420px;
  }
  .Story_steamCycle__25JlA {
    float: left;
    margin: 0 22px 8px 0;
  }
  .Story_museeMecaniqueLogo__zenvm {
    float: right;
    margin: 0 0 8px 22px;
  }
}

.Gallery_wrapper__1rIEt {
  position: relative;
  height: 100%;
  overflow: none;
  /* background: black; */
  /* border-bottom: 4px solid white; */
}

.Gallery_virtualTourBanner__2PPun {
  background: var(--brown);
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}

.Gallery_virtualTourLink__2WZq8 {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: var(--white);
}

.Gallery_virtualTourLink__2WZq8:hover {
  color: var(--white);
}

.Gallery_linkText__1XHlN {
  text-decoration: underline;
}

.Gallery_mainImage__2KMiW {
  height: calc(100vh - 112px);
  width: 100%;
  background: url(/static/media/galleryBackground.0b051a16.jpg);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}

@media (min-width: 992px) {
  .Gallery_mainImage__2KMiW {
    height: calc(100vh - 155px);
    /* width: 100%; */
  }
}

.ContactForm_wrapper__2i2MM {
}

.ContactForm_header__3iCzm {
  font-family: var(--header-font-stack);
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: var(--black);
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .ContactForm_header__3iCzm {
    text-align: left;
  }
}

.ContactForm_message__2w1Rv {
  font-family: var(--body-font-stack);
  font-size: 14px;
}

label.ContactForm_label__1i3rK {
  font-size: 14px !important;
  -webkit-user-select: none;
          user-select: none;
}

.ContactForm_validationLabel__1wXxC {
  font-size: 12px !important;
  -webkit-user-select: none;
          user-select: none;
}

.ContactForm_input__ItqZo,
.ContactForm_validationLabel__1wXxC,
label.ContactForm_label__1i3rK {
  font-family: var(--body-font-stack) !important;
  color: var(--black) !important;
}

.ContactForm_charactersRemaining__hCtMO {
  margin-top: 6px;
  display: block;
  font-family: var(--body-font-stack);
  color: var(--grey);
  font-size: 12px;
}

.Contact_wrapper__3rgT8 {
  padding: 40px 0;
}

@media (max-width: 767px) {
  div.Contact_topContainer__1RpVq.Contact_oneHundred__s5Ci0 {
    margin: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.Contact_topHeader__3xHid {
  font-family: var(--header-font-stack);
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: var(--black);
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 32px;
}

.Contact_topWrapper__3mySb {
  width: 100%;
}

.Contact_contactInfoWrapper__wVonF {
  padding: 0 14px;
  width: 100%;
  margin-bottom: 24px;
}

.Contact_contactDetailWrapper__3GwLG {
  margin: 12px 0;
  font-size: 16px;
  font-family: var(--body-font-stack);
  color: var(--black);
}

.Contact_contactDetail__7_2zI {
  font-weight: 600;
  display: inline-block;
  margin-left: 12px;
}

.Contact_contactInfoBlock__ZxV1m {
  margin-top: 32px;
}

.Contact_contactInfoHeader__WH2GL {
  font-family: var(--body-font-stack);
  font-weight: 600;
  margin: 6px 0 16px;
  font-size: 18px;
  color: var(--black);
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.Contact_contactInfoLine__3iHBj {
  font-family: var(--body-font-stack);
  font-weight: 400;
  margin: 6px 0;
  font-size: 16px;
  color: var(--black);
}

.Contact_contactInfoLine__3iHBj > p {
  line-height: 22px;
}

.Contact_mapWrapper__bToKR {
  width: 100%;
  height: 420px;
}

.Contact_divider__WURS8 {
  margin: 40px 0 !important;
}

.Contact_addressWrapper__1KHbM {
  font-style: normal;
}

@media (min-width: 768px) {
  .Contact_topHeader__3xHid {
    text-align: left;
  }
  .Contact_topWrapper__3mySb {
    display: flex;
  }
  .Contact_contactInfoWrapper__wVonF {
    width: 50%;
    padding: 0;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .Contact_mapWrapper__bToKR {
    width: 50%;
    height: 500px;
    padding-left: 20px;
  }
}

.Slideshow_wrapper__sdXhX {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin: 0 auto;
}

.Slideshow_innerWrapper__H-XpR {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Slideshow_innerWrapper__H-XpR > div,
.Slideshow_innerWrapper__H-XpR > div > div,
.Slideshow_innerWrapper__H-XpR > div > div > div,
.Slideshow_innerWrapper__H-XpR > div > div > div > div,
.Slideshow_innerWrapper__H-XpR > div > div > div > div > div {
  width: 100%;
  height: 100%;
}

.Slideshow_imageWrapper__1_ES6 {
  background: var(--black);
  height: 100%;
}

.Slideshow_image__2G6ye {
  height: 100%;
  width: 100%;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-size: 86% !important;
}

.ShopItem_wrapper__2q9xi {
  position: relative;
  padding: 40px 0 80px;
}

.ShopItem_innerWrapper__1NSMx {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ShopItem_image__2mvKV {
  position: relative;
  width: 80%;
  padding-top: 80%;
  margin: 0 auto 48px;
}

.ShopItem_slideshowWrapper__2oNtg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.ShopItem_info___dtNh {
  width: 100%;
}

.ShopItem_title__1jRN3 {
  font-family: var(--body-font-stack);
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ShopItem_price__3lhnW {
  font-family: var(--body-font-stack);
  color: var(--dark-grey);
  font-size: 18px;
  font-weight: 700;
  margin-top: 0;
}

.ShopItem_addToCartButton__2RAmI {
  margin-bottom: 6px;
}

.ShopItem_samplerWrapper__iTzKf {
  margin: 16px 0;
  width: 100%;
}

.ShopItem_samplerLabel__NDsnt {
  font-family: var(--body-font-stack);
  color: var(--dark-grey);
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
  cursor: default;
}

.ShopItem_sampler__3z5es {
  width: 100%;
}

.ShopItem_description__ZKKVd {
  font-family: var(--body-font-stack);
  color: var(--black);
  line-height: 26px;
  font-size: 15px;
  padding: 8px 0;
}

.ShopItem_backLinkWrapper__3_lV0 {
  margin: 0 auto 32px;
}

.ShopItem_backLink__2Vp8G {
  font-size: 16px;
}

@media (min-width: 640px) {
  .ShopItem_image__2mvKV {
    width: 40%;
    padding-top: 40%;
  }
  .ShopItem_info___dtNh {
    width: 50%;
  }
  .ShopItem_backLinkWrapper__3_lV0 {
    width: 90%;
  }
}

.App_wrapper__1U26A {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App_content__1A4-v {
  flex: 1 0 auto;
  display: relative;
  overflow: hidden;
}

