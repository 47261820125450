.wrapper {
}

.welcomeLine {
  font-weight: 600;
  font-family: var(--header-font-stack);
  font-size: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.text {
  font-family: var(--body-font-stack);
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  color: var(--black);
  padding: 8px 0;
}

.icons {
  padding-top: 56px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.icons > a {
  display: inline-block;
}

.icons > a > i {
  color: var(--black);
  font-size: 32px;
  margin: 0 12px;
  transition: 0.3s all ease;
}

.icons > a:hover > i {
  transform: scale(1.1);
}
