.wrapper {
  padding: 40px 0;
}

@media (max-width: 767px) {
  div.topContainer.oneHundred {
    margin: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.topHeader {
  font-family: var(--header-font-stack);
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: var(--black);
  cursor: default;
  user-select: none;
  margin-bottom: 32px;
}

.topWrapper {
  width: 100%;
}

.contactInfoWrapper {
  padding: 0 14px;
  width: 100%;
  margin-bottom: 24px;
}

.contactDetailWrapper {
  margin: 12px 0;
  font-size: 16px;
  font-family: var(--body-font-stack);
  color: var(--black);
}

.contactDetail {
  font-weight: 600;
  display: inline-block;
  margin-left: 12px;
}

.contactInfoBlock {
  margin-top: 32px;
}

.contactInfoHeader {
  font-family: var(--body-font-stack);
  font-weight: 600;
  margin: 6px 0 16px;
  font-size: 18px;
  color: var(--black);
  cursor: default;
  user-select: none;
}

.contactInfoLine {
  font-family: var(--body-font-stack);
  font-weight: 400;
  margin: 6px 0;
  font-size: 16px;
  color: var(--black);
}

.contactInfoLine > p {
  line-height: 22px;
}

.mapWrapper {
  width: 100%;
  height: 420px;
}

.divider {
  margin: 40px 0 !important;
}

.addressWrapper {
  font-style: normal;
}

@media (min-width: 768px) {
  .topHeader {
    text-align: left;
  }
  .topWrapper {
    display: flex;
  }
  .contactInfoWrapper {
    width: 50%;
    padding: 0;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .mapWrapper {
    width: 50%;
    height: 500px;
    padding-left: 20px;
  }
}
