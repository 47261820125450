.wrapper {
  position: relative;
  padding: 40px 0 80px;
}

.header {
  font-family: var(--header-font-stack);
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  margin: 0 0 12px 0;
  color: var(--black);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subHeader {
  font-family: var(--body-font-stack);
  text-align: center;
  font-weight: 600;
  margin: 0 0 32px 0;
  font-size: 15px;
  color: var(--dark-grey);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text {
  font-family: var(--body-font-stack);
  font-weight: 400;
  line-height: 26px;
  font-size: 15px;
  color: var(--black);
  padding: 8px 0;
}

.museeMecaniqueLogo {
  position: relative;
  margin-top: 32px;
}

.steamCycle,
.museeMecaniqueLogo {
  margin: 0 auto 22px;
  display: block;
  width: 90%;
  border: 2px solid var(--black);
}

@media (min-width: 768px) {
  .header,
  .subHeader {
    text-align: left;
  }
  .steamCycle,
  .museeMecaniqueLogo {
    display: inline-block;
    width: 420px;
  }
  .steamCycle {
    float: left;
    margin: 0 22px 8px 0;
  }
  .museeMecaniqueLogo {
    float: right;
    margin: 0 0 8px 22px;
  }
}
